import React, { Component } from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import Slider from "react-slick";


class Work_Cafes extends Component{
  constructor(){
    super();
    this.state={
      banner_img:[],
      base_url:global.BaseUrl,
      
    }
  }

  componentDidMount(){
    fetch(`${this.state.base_url}/getBannerById/11`).then((resp)=>{
      resp.json().then((result)=>{
         console.log(result);
        this.setState({ banner_img: result.banner_images});
        // this.setState({ headingType: result.data});           
      })
    });
  }


      render() {
        const {banner_img} = this.state;

        var settings = {
          className: "",
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 7000,
          pauseOnHover: false,
          cssEase: 'linear',
          adaptiveHeight: true
          
        };

      
        return(
            <>

      
<div className="container-fluid slider_top">
            <div className="row">
              <div className="col-md-4 col-12">
                <img src="./assets/images/banner/work_one.jpg" alt="" className='bnr_img_30 padding_set' />
              </div>
              <div className="col-md-4 col-12">
                <img src="./assets/images/banner/work_three.jpg" alt="" className='bnr_img_30 padding_set mobile_hide' />
              </div>
              <div className="col-md-4 col-12">
                <img src="./assets/images/banner/work_two.jpg" alt="" className='bnr_img_30 padding_set mobile_hide' />
              </div>
            </div>
      </div>
                   <div>
      </div>
            <section className="section-reservation-form padding-bottom-100">
              <div class="container">
            <div class="swin-sc swin-sc-title style-2">

                    <h3 class="title"><span>WORK CAFES</span></h3>
                    <p class="cafe-text text_justify">Work cafés have been aligned with new global standards embracing thematic design elements to create out of Workplace area concepts & to enhance overall experience.</p>
                      </div>
                  
                  <div class="reservation-form">
                    <div class="row">
                      <div class="col-md-8 col-md-offset-2">
                      {/* <h4 class="service-title text-center">What Do Work Cafes Offer:</h4> */}
                      {/* <p class="cafe-text text-center">Our amenities aim to provide the best employee experience across our cafes and pantry areas</p> */}
                      </div>
                    </div>
                    {/* <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-12">
                          <ul class="list-style">
                            <li>Beverage Vending: Our beverage machines offer a wide variety of both hot and cold beverages</li>
                            <li>Hydration Station: Clean, filtered water available:  hot or cold</li>
                            <li>Refrigerators (Vegetarian & Non-Vegetarian) to store home cooked food</li>
                            <li>Microwave Oven: Provided for reheating homecooked meals using microwave-safe containers</li>
                            <li>Snack Spot: Retail grab-scan-pay-go micro market, installed on all floors</li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-md-12">                        
                         <div className="box-shadow">
                         <div className="work_sustain">
                            <img src="./assets/images/icon/Beverage-machine.png" alt="" />
                            <p className="sustain_new">Tea/Coffee Vending </p>
                           </div>
                           <div>
                             <p className="sustain_new_para">Wide variety of hot coffees and handpicked selection of teas</p>
                           </div>
                         </div>
                      </div>
                      </div>

                      <div className="row">
                      <div className="col-md-6">                        
                          <div className="box-shadow">
                          <div className="work_sustain">
                            <img src="./assets/images/icon/filter-water.png" alt="" />
                            <p className="sustain_new">Hydration Station</p>
                           </div>
                           <div>
                             <p className="sustain_new_para">touch-based panel dispensing 3 states of water i.e. Hot, cold and Ambient</p>
                           </div>
                          </div>
                      </div>

                      <div className="col-md-6">                        
                          <div className="box-shadow">
                          <div className="work_sustain">
                            <img src="./assets/images/icon/microwave.png" alt="" />
                            <p className="sustain_new">Mini Markets</p>
                           </div>
                           <div>
                             <p className="sustain_new_para">Available on select floors</p>
                           </div>
                          </div>
                      </div>

                      <div className="col-md-6">                        
                          <div className="box-shadow" style={{padding:'25px'}}>
                          <div className="work_sustain">
                            <img src="./assets/images/icon/fridge.png" alt="" />
                            <p className="sustain_new">Refrigerators  </p>
                           </div>
                           <div>
                             <p className="sustain_new_para">to store home cooked food</p>
                           </div>
                          </div>
                      </div>

                    

                      <div className="col-md-6">                        
                          <div className="box-shadow">
                          <div className="work_sustain">
                            <img src="./assets/images/icon/vending-machine.png" alt="" />
                            <p className="sustain_new">Mug Concept</p>
                           </div>
                           <div>
                             <p className="sustain_new_para">Mug concept - leading to elimination of paper cups and fortifying our sustainable practices</p>
                           </div>
                          </div>
                      </div>
                      </div>

                    
                    <div class="swin-sc swin-sc-contact-form light mtl">

                    </div>
                  </div>
                  </div> 
            </section>
            </>
        );
    }
}

export default Work_Cafes;
