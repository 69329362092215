import React, { Component } from 'react'
import './Style.css';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

export default class Allergens extends Component{
    render(){
   
     
       return(
        <> 
      
      <div className="section-reservation-form section_padding">
      <div class="row p-btm">
          <div className="container">
            <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="swin-sc swin-sc-title style-2">
                    <h3 className="title swin-sc-title"><span>Allergens</span></h3>
             </div>
            </div>
          </div>
        </div>
        <div className="row padding-bottom-100 " style={{color:'#000'}}>
          <div className="container">
            <div className="row ">

            <div className="text_justify">
             <b> 1) What Is a Food Allergy ?</b><br/>
             A food allergy is a condition in which certain foods trigger an abnormal immune response. It’s caused by your immune system wrongly recognizing some of the proteins in a food as harmful. Your body then launches a range of protective measures, including releasing chemicals like histamine, which causes inflammation. For people who have a food allergy, even exposure to very small amounts of the problem food can cause an allergic reaction. Symptoms can occur anywhere from a few minutes after exposure to a few hours later.
                </div>

              <div className="text_justify mt-4">
             <b> 2) Here are the eight most common food allergies.</b><br/>
             <div className="text-center">
            <img src="./assets/images/allergens_banner-new.jpg" className="img-fluid text-center" style={{width:'30%'}} />
            </div>
            <br/>
            <b>i. Milk</b><br/>
            Mostly affects children under the age of three. A diagnosis of milk allergy means that all milk and milk products must be avoided.
            <br/>
            <br/>
            <b>ii. Eggs</b><br/>
            The most common type of egg allergy is an egg white allergy. The treatment is an egg-free diet. However, some people may be able to reintroduce some foods containing cooked eggs into their diet.
                <br/>
                <br/>
                <b>iii. Treenuts</b><br/>
                A tree nut allergy is one of the most common food allergies. It’s frequently associated with severe allergic reactions, and the treatment is usually a lifelong avoidance of all tree nuts and tree nut products.
                <br/>
                <br/>
                <b>iv. Peanuts</b><br/>
                A peanut allergy is a serious condition that can cause a severe allergic reaction. Treatment is lifelong avoidance of peanuts and peanut-containing products.
                <br/>
                <br/>
                <b>v. Shellfish</b><br/>
                The most common trigger of a shellfish allergy is a protein called tropomyosin. The only treatment for a shellfish allergy is removing all shellfish from your diet.
                <br/>
                <br/>
                <b>vi. Wheat</b><br/>
                A wheat allergy can be caused by a sensitivity to any of the hundreds of proteins in wheat. The only treatment is a wheat-free diet, but many people outgrow it before they reach school age.
                <br/>
                <br/>
                <b>vii. Soy</b><br/>
                A soy allergy is triggered by the proteins in soybeans and soybean products. If you have a soy allergy, the only treatment is the removal of soy and soy based products from your diet.                
                <br/>
                <br/>
                <b>viii. Fish</b><br/>
                Unlike other allergies, it’s not uncommon for a fish allergy to surface later in life, with 40% of people developing the allergy as an adult.

                
                
                
                
                </div> 

                <div className="mt-4">
             {/* <b> 3) Here are the eight most common food allergies.</b><br/> */}
             {/* <b> 3) The Big 8</b><br/>

             <p className="text_justify">Certain food groups account for about 90% of all food allergies worldwide. These food groups are also known as The Big 8. These major allergenic food groups are as follows:<b> milk, eggs, fish, crustacean shellfish, tree nuts, peanuts, wheat, and soybean.</b> </p>
            <p className="text_justify">Allergic consumers may accidentally encounter problem foods as several of the Big 8 are often used in processed products and can be hidden.</p>
           
            <p className="text_justify mt-4">For further questions regarding food allergens, please reach out to the Café manager.</p>   */}

          </div> 
 

           

            <div className="mt-4">
             <b> 3) Other Foods</b><br/>
The 8 food allergies outlined above are the most common ones. However, any food can cause an allergy. Other foods people are allergic to include fruits, vegetables and seeds like:<br/>
•	Linseed<br/>
•	Sesame seed<br/>
•	Peach<br/>
•	Banana<br/>
•	Avocado<br/>
•	Kiwi fruit<br/>
•	Passion fruit<br/>
•	Celery<br/>
•	Garlic<br/>
•	Mustard seeds<br/>
•	Aniseed<br/>
•	Chamomile
                </div>
<div>
  <br/>
  <b>4) Can I request for an allergen free meal in the café?</b><br/>
  An allergen free meal requires an extremely controlled environment which may not be available while cooking a huge number of meals. Although utmost care is taken for all areas of food production, the products prepared in our kitchen may have come into contact with the most common allergens. Employees having food allergies are advised to use their discretion while consuming food prepared in the in-house kitchens. If your allergic reactions are severe, it is advised to consume home cooked food only.

</div>

                <div className="mt-4 text_justify">
             <b> 5) Further questions?</b><br/>
             Please reach out to the Café Manager for any further questions regarding food allergens.                </div>
          </div>
          </div> 
      </div>
      </div>
    </>
        ) }
       }
